<script>
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseModal',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowClickAway: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const modalMask = ref(null);
    function clickAway({ target }) {
      const hasClickedMask = target === modalMask.value;
      if (!hasClickedMask) {
        return;
      }
      if (!props.allowClickAway) {
        return;
      }
      emit('update:show', false);
    }
    return {
      clickAway,
      modalMask,
    };
  },
});
</script>

<template>
  <transition name="fade">
    <div
      data-testid="modalMask"
      v-if="show"
      ref="modalMask"
      class="fixed top-0 left-0 min-h-screen min-w-screen bg-black w-full bg-opacity-60 z-20"
    >
      <div
        v-click-away="clickAway"
        class="
          z-20
          w-screen
          h-screen
          max-w-lg max-h-sm
          p-3
          bg-white
          m-auto
          rounded-none
          sm:rounded-lg sm:my-10 sm:h-full sm:w-full
          overflow-scroll
          hide-scroll
        "
      >
        <slot />
      </div>
    </div>
  </transition>
</template>

<style scoped>
/* Hide scrollbar for chrome and webkit browsers */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
