<script>
import { defineComponent } from '@vue/composition-api';
import { useBrand } from '@/use/brand';
import { useRouter } from '@/use/router';
import BaseButton from '@/components/base/base-button.vue';
import { ROUTE_CARD_PAYMENT } from '@/lib/router';

export default defineComponent({
  name: 'UserNotApproved',
  components: { BaseButton },
  props: {
    artifactId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const brand = useBrand();
    const router = useRouter();
    function routeToPayInFullViaCard() {
      router.push({
        name: ROUTE_CARD_PAYMENT,
        params: { artifactId: props?.artifactId, isBnplApproved: false } },
      );
    }
    return {
      brand,
      router,
      routeToPayInFullViaCard,
    };
  },
});
</script>

<template>
  <div class="flex flex-col mt-16 md:mt-0 max-w-screen-md mx-auto">
    <h1 class="heading-2 md:heading-1 text-center mb-7 md:my-8">Sorry, we couldn't approve you for Payment Plans</h1>
    <p class="text-shades-dark text-center mb-5">
      Unfortunately, we were unable to approve you for a Payment Plan this time but you can still complete payment
      <span class="font-bold text-shades-darker"> via credit or debit card. </span>
    </p>
    <p class="font-bold text-shades-darker my-4">Why was I not approved?</p>
    <p class="font-bold text-shades-darker">There are a few reasons why this might be:</p>
    <div class="ml-6">
      <ul class="list-disc text-shades-dark">
        <li>The bank account you linked does not have enough funds for transaction</li>
        <li>You have too many payments in progress at the moment</li>
        <li>The risk rating has changed</li>
        <li>Your affordability has been recalculated</li>
      </ul>
    </div>
    <p class="text-shades-dark mt-4">
      From here, you can still pay in full via debit or credit card. If you think we've made a mistake send us a message
      at<a class="cursor-pointer text-primary-dark" :href="`mailto:${brand.contactEmail}`"> {{ brand.contactEmail }}</a>
      or call us at
      <span class="cursor-pointer text-primary-dark">{{ brand.contactPhone }}.</span>
    </p>
    <div class="flex flex-col items-center my-8">
      <BaseButton class="w-9/12" rounded @click="routeToPayInFullViaCard">Continue to Pay in full via card</BaseButton>
    </div>
  </div>
</template>

<style scoped>
</style>
