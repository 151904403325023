<script>
import { defineComponent } from '@vue/composition-api';
import { useBrand } from '@/use/brand';

export default defineComponent({
  name: 'UnapprovedForPlanInfoCard',
  setup() {
    const brand = useBrand();
    return {
      brand,
    };
  },
});
</script>

<template>
  <div class="flex flex-col m-4">
    <p class="heading-2 mb-2">Sorry, we couldn't approve you for Payment Plans</p>
    <p class="text-shades-darker mb-4 text-sm">
      We were unable to approve you for a Payment Plan this time but you can still complete payment via credit or debit
      card.
    </p>
    <p class="text-shades-darker text-sm">Here are a few reasons why this might be:</p>
    <div class="ml-6 text-sm">
      <ul class="list-disc text-shades-darker">
        <li>The bank account you linked does not have enough funds for transaction</li>
        <li>You have too many payments in progress at the moment</li>
        <li>The risk rating has changed</li>
        <li>Your affordability has been recalculated</li>
      </ul>
    </div>
    <p class="text-shades-darker mt-4 text-sm">
      From here you can still pay in full via debit or credit card or if you think we've made a mistake send us a
      message at
      <a class="text-link" :href="`mailto:${brand.contactEmail}`">{{ brand.contactEmail }}</a> or call us at
      <span>{{ brand.contactPhone }}</span>
    </p>
  </div>
</template>

<style scoped></style>
