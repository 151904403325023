<script>
import { defineComponent } from '@vue/composition-api';
import BaseIcon from '@/components/base/base-icon.vue';

export default defineComponent({
  name: 'SupportContactInfoCard',
  components: {
    BaseIcon,
  },
  props: {
    artifactDetails: {
      type: Object,
      required: true,
    },
  },
});
</script>

<template>
  <div class="p-2 md:p-4">
    <div class="flex justify-between mb-2">
      <h1 class="heading-3 mb-4 text-left md:text-center">Contact {{ artifactDetails.partyName }}</h1>
      <BaseIcon icon="fa-times" size="fa-lg" class="ml-4 md:ml-0" />
    </div>
    <div v-show="artifactDetails.contactNumber" class="flex items-center" data-testid="phonegroup">
      <BaseIcon class="rotate-90 mr-3" icon="fa-phone" size="fa-lg" />
      <a class="md:hidden" :href="`tel:${artifactDetails.contactNumber}`">{{ artifactDetails.contactNumber }}</a>
      <p class="hidden md:block">{{ artifactDetails.contactNumber }}</p>
    </div>
    <div v-show="artifactDetails.contactEmail" class="flex items-center mt-3">
      <BaseIcon class="mr-3" icon="fa-envelope" size="fa-lg" />
      <a class="text-link" :href="`mailto:${artifactDetails.contactEmail}`">{{ artifactDetails.contactEmail }}</a>
    </div>
  </div>
</template>

<style scoped>
</style>
