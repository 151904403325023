/* eslint-disable import/prefer-default-export */

import { apiGet, apiPost } from '@/lib/api';

const baseUrl = `${process.env.VUE_APP_API_URL}/api/v3/payments/card`;

/**
 * @typedef CardPaymentToken
 * @property {String} keyId payment context token
 */

/**
 * @typedef CardPaymentRequest
 * @property {string} totalAmount - total amount to pay
 * @property {string} reference - unique identifier
 * @property {string} paymentToken - card provider payment token
 * @property {Object} payer - payer
 * @property {string} payer.firstName - payee's first name
 * @property {string} payer.lastName - payee's last name
 * @property {string} payer.rawAddress -  raw address
 * @property {string} payer.streetAddress - street address
 * @property {string} payer.suburb - suburb
 * @property {string} payer.state - state
 * @property {string} payer.postcode - postcode
 */

/**
 * request a card payment token
 * @returns {Promise<import('axios').AxiosResponse<CardPaymentToken>>}
 */
export function postRequestToken() {
  const url = `${baseUrl}/token?format=JWT`;
  return apiGet(url);
}

/**
 * Posts a card payment and receives a receipt
 * @param {CardPaymentRequest} payload - card payment request
 * @returns
 */
export function postCardPayment(payload = {}) {
  const url = `${baseUrl}`;
  return apiPost(url, payload);
}

/**
 *
 * @param {String} email email to send copy of the receipt
 * @returns {Promise<import('axios').AxiosResponse>}
 */
export function sendReceiptToEmail(artifactId, email) {
  const url = `${baseUrl}/receipt`;
  return apiPost(url, { email, artifactId });
}
