<script>
import { defineComponent } from '@vue/composition-api';
import { formatCurrency } from '@/lib/formats';
import { useBrand } from '@/use/brand';
import BaseCard from '@/components/base/base-card.vue';

export default defineComponent({
  name: 'PaymentArtifactHeader',
  components: {
    BaseCard,
  },
  props: {
    artifactDetails: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const brand = useBrand();
    return {
      formatCurrency,
      brand,
    };
  },
});
</script>

<template>
  <div class="flex flex-auto flex-col md:flex-row items-center lg:justify-start mb-5 md:my-10">
    <img class="rounded-lg mx-6 w-1/3 md:w-1/6 mb-2 md:mb-0" :src="artifactDetails.partyLogo" alt="merchant logo" />
    <BaseCard>
      <p class="font-semibold mb-1.5">Amount due {{ formatCurrency(artifactDetails.amount) }}</p>
      <p class="mb-1.5">
        to <span class="font-semibold">{{ artifactDetails.partyName }} </span>
      </p>
      <p class="text-xs text-shades-darker">Paying later with Payment Plans</p>
    </BaseCard>
  </div>
</template>

<style scoped>
</style>
