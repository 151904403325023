<script>
import { computed, defineComponent, ref } from '@vue/composition-api';
import PlanSchedule from '@/components/plan-schedule.vue';
import BaseCheckbox from '@/components/base/base-checkbox.vue';
import BaseButton from '@/components/base/base-button.vue';
import PaymentArtifactHeader from '@/components/payment-artifact-header.vue';
import { useBrand } from '@/use/brand';
import { useRouter } from '@/use/router';
import { ROUTE_ARTIFACT_RECEIPT, ROUTE_PAY } from '@/lib/router';
import { confirmPaymentPlan } from '@/api/bnpl-payments';
import ErrorNotification from '@/components/error-notification.vue';
import { useApiErrors } from '@/use/errors';

export default defineComponent({
  components: { PlanSchedule, BaseCheckbox, BaseButton, PaymentArtifactHeader, ErrorNotification },
  name: 'PaymentConfirmation',
  props: {
    artifactDetails: {
      type: Object,
      required: true,
    },
    paymentOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const brand = useBrand();
    const isLoading = ref(false);
    const { isError, apiError, setError, clearError } = useApiErrors();
    const termsForm = ref(false);

    function routeToPay() {
      router.push({ name: ROUTE_PAY, params: { artifactId: props.artifactDetails.artifactId } });
    }

    async function confirmAndPay() {
      try {
        clearError();
        const { data } = await confirmPaymentPlan(props.artifactDetails.artifactId);
        router.push({
          name: ROUTE_ARTIFACT_RECEIPT,
          params: {
            artifactDetails: props.artifactDetails,
            receiptDetails: data?.receiptDetails,
          },
        });
      } catch (error) {
        setError(error);
      }
    }

    const bnplOption = computed(() => {
      // currently we are only allowing 1 bnpl option for firms
      const [bnpl] = props.paymentOptions.filter((opt) => opt.productType !== 'Card');
      return bnpl;
    });

    return {
      bnplOption,
      brand,
      termsForm,
      routeToPay,
      confirmAndPay,
      isLoading,
      isError,
      apiError,
    };
  },
});
</script>

<template>
  <div class="p-2 max-w-lg mx-auto mt-5">
    <PaymentArtifactHeader :artifactDetails="artifactDetails" />
    <h1 class="heading-2 md:heading-1 text-center">Confirm Payment Plan</h1>
    <p class="text-sm text-shades-darker text-center my-4">
      Your application has been approved. Read through the plan details and confirm your Payment Plan.
    </p>
    <PlanSchedule :paymentOption="bnplOption" />
    <form @submit.prevent="confirmAndPay">
      <BaseCheckbox required class="my-8" v-model="termsForm">
        <p class="text-xs text-shades-darker">
          Check this box to confirm acceptance of the
          <a class="text-link" target="_blank" :href="brand.customerTerms">Terms and Conditions</a> and acknowledge I
          will be entering into a payment plan with {{ brand.name }} Payment Plans Pty Ltd.
        </p>
      </BaseCheckbox>
      <ErrorNotification class="my-4" v-show="isError" :errorMessages="apiError" />
      <div class="flex justify-center">
        <BaseButton :loading="isLoading" :disabled="isError" type="submit" rounded class="w-full md:w-72">
          Confirm and Pay
        </BaseButton>
      </div>
    </form>
    <div class="my-8 text-sm text-center">
      <a class="text-link" @click="routeToPay">or Pay in ful via card instead</a>
    </div>
  </div>
</template>

<style scoped>
</style>
