/* eslint-disable global-require */
<script>
import { computed, defineComponent, ref } from '@vue/composition-api';
import currency from 'currency.js';
import IconCheckCircle from '@/components/icon-check-circle.vue';
import { formatCurrency, formatDate, formatFirmLegalIdentifierType } from '@/lib/formats';
import { useBrand } from '@/use/brand';
import BaseInput from '@/components/base/base-input.vue';
import BaseButton from '@/components/base/base-button.vue';
import BaseModal from '@/components/base/base-modal.vue';
import BaseIcon from '@/components/base/base-icon.vue';
import notification from '@/components/notification.vue';
import ErrorNotification from '@/components/error-notification.vue';
import SupportContactInfoCard from '@/components/support-contact-info-card.vue';
import { sendReceiptToEmail } from '@/api/card-payment';
import { useRouter } from '@/use/router';
import { ROUTE_CUSTOMER_DASHBOARD } from '@/lib/router';
import { useApiErrors } from '@/use/errors';
import VISA from '@/assets/card-icon-visa.svg';
import MASTERCARD from '@/assets/card-icon-mastercard.svg';
import AMEX from '@/assets/card-icon-amex.svg';
import JCB from '@/assets/card-icon-jcb.svg';

export default defineComponent({
  name: 'PaymentReceipt',
  components: {
    IconCheckCircle,
    BaseInput,
    BaseButton,
    BaseIcon,
    BaseModal,
    notification,
    ErrorNotification,
    SupportContactInfoCard,
  },
  props: {
    profile: {
      type: Object,
      required: false,
    },
    artifactDetails: {
      type: Object,
      required: true,
    },
    receiptDetails: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const brand = useBrand();
    const emailForm = ref('');
    const isLoading = ref(false);
    const emailSent = ref(false);
    const { apiError, isError, clearError, setError } = useApiErrors();
    const showModal = ref(false);

    async function sendReceipt() {
      try {
        clearError();
        isLoading.value = true;
        await sendReceiptToEmail(props?.artifactDetails?.artifactId, emailForm.value);
        emailSent.value = true;
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        isLoading.value = false;
      }
    }
    const router = useRouter();

    function routeToDashboard() {
      router.push({ name: ROUTE_CUSTOMER_DASHBOARD });
    }

    const recurringRepayment = computed(() => props?.receiptDetails?.schedule[1].amount);
    const firstRepayment = computed(() => props?.receiptDetails?.schedule[0].amount);
    const remainingAmount = computed(() => {
      let amount = currency(0);
      const remaining = props?.receiptDetails?.schedule.slice(1);
      remaining.forEach((item) => {
        amount = amount.add(currency(item.amount));
      });
      return amount.toString();
    });
    function getCardLogo(cardType) {
      const cardLogos = {
        VISA,
        AMEX,
        MASTERCARD,
        JCB,
      };
      return cardLogos[cardType];
    }
    function toggleModal() {
      showModal.value = !showModal.value;
    }
    const isCardPayment = computed(() => props?.receiptDetails?.paymentMethod === 'Card');
    const renderTitle = computed(() => (props.profile ? `Thank you, ${props.profile.firstName}` : 'Thank you'));

    return {
      emailForm,
      isLoading,
      brand,
      formatCurrency,
      formatDate,
      formatFirmLegalIdentifierType,
      renderTitle,
      isCardPayment,
      recurringRepayment,
      firstRepayment,
      remainingAmount,
      sendReceipt,
      routeToDashboard,
      emailSent,
      isError,
      apiError,
      clearError,
      getCardLogo,
      toggleModal,
      showModal,
    };
  },
});
</script>

<template>
  <div class="max-w-md mx-auto my-10 relative flex flex-col justify-center lg:my-20 lg:block">
    <div class="w-full mb-3 text-center lg:text-justify lg:mb-0 lg:w-60 lg:absolute lg:top-0 lg:right-full lg:mr-5">
      <img v-show="artifactDetails.partyLogo" class="mb-2 mx-auto" :src="artifactDetails.partyLogo" alt="firm-logo" />
      <div class="border rounded-lg shadow-sm text-sm p-4">
        <p class="heading-2 mb-4 md:text-left">{{ artifactDetails.partyName }}</p>
        <p class="hidden lg:block">{{ formatFirmLegalIdentifierType() }} {{ artifactDetails.partyLegalIdentifier }}</p>
        <p class="mb-4 hidden lg:block">{{ artifactDetails.partyAddress }}</p>
        <span
          v-if="artifactDetails.contactEmail || artifactDetails.contactNumber"
          class="cursor-pointer"
          @click="toggleModal"
        >
          <div class="flex items-center justify-center lg:justify-start">
            <BaseIcon class="rotate-90 mr-2" icon="fa-phone" size="fa-lg" />
            <a class="text-link">Contact Firm</a>
          </div>
          <BaseModal class="rounded" :show.sync="showModal">
            <SupportContactInfoCard :artifactDetails="artifactDetails" />
          </BaseModal>
        </span>
      </div>
    </div>
    <div class="flex flex-col items-center border rounded-lg shadow-md p-4">
      <IconCheckCircle width="55" height="55" />

      <template v-if="isCardPayment">
        <div class="text-center">
          <h3 class="heading-2 my-2">{{ renderTitle }}</h3>
          <p class="text-xl mb-2">Amount paid {{ formatCurrency(receiptDetails.totalAmount) }}</p>
          <p class="text-xs">
            via {{ receiptDetails.paymentMethod }} (incl. surcharge of {{ formatCurrency(receiptDetails.fees) }})
          </p>
          <hr class="my-6" />
        </div>
      </template>

      <template v-else>
        <div class="text-center">
          <h3 class="heading-2 my-2">Thank you, {{ profile.firstName }}</h3>
          <p class="text-xl mb-2">Amount paid {{ formatCurrency(receiptDetails.totalAmount) }}</p>
          <p class="text-xs mb-2">
            split into {{ formatCurrency(recurringRepayment) }} per fortnight for
            {{ receiptDetails.paymentMethod }}
          </p>
          <p class="text-xs font-bold">Today paid {{ formatCurrency(firstRepayment) }} (incl. Plan Setup Fee)</p>
          <hr class="my-6" />
        </div>
      </template>

      <div class="w-full max-w-md text-sm">
        <p class="text-lg font-bold mb-2">Summary</p>
        <p class="">{{ receiptDetails.billerName }} to {{ artifactDetails.partyName }}</p>
        <p class="text-shades-dark mb-2">Reference {{ artifactDetails.externalId }}</p>
        <div class="payment-receipt-row">
          <span>Amount due</span>
          <span>{{ formatCurrency(receiptDetails.amount) }}</span>
        </div>
        <div class="payment-receipt-row">
          <span v-if="isCardPayment">Card surcharge</span>
          <span v-else>Plan Setup Fee</span>
          <span>{{ formatCurrency(receiptDetails.fees) }}</span>
        </div>
        <div class="payment-receipt-row">
          <span class="font-bold">Total</span>
          <span class="font-bold">{{ formatCurrency(receiptDetails.totalAmount) }}</span>
        </div>
        <div class="mb-2">
          <p class="font-bold text-lg mt-2 mb-1">Payment method</p>
          <p v-if="isCardPayment">{{ receiptDetails.paymentMethod }}</p>
          <p v-else class="text-shades-darker">Fortnightly payments over {{ receiptDetails.paymentMethod }}</p>
        </div>

        <template v-if="isCardPayment">
          <div class="payment-receipt-row">
            <span class="font-bold">Today Paid</span>
            <span class="font-bold">{{ formatCurrency(receiptDetails.totalAmount) }}</span>
          </div>
        </template>

        <template v-else>
          <div class="payment-receipt-row">
            <span class="font-bold">Today Paid</span>
            <span class="font-bold">{{ formatCurrency(firstRepayment) }}</span>
          </div>
          <div class="payment-receipt-row">
            <span class="font-bold text-shades">Today Remaining</span>
            <span class="font-bold text-shades">{{ formatCurrency(remainingAmount) }}</span>
          </div>
          <span class="text-shades">Next payment due {{ formatDate(receiptDetails.schedule[1].dueDate) }} </span>
        </template>

        <div class="mb-4">
          <p class="text-lg font-bold my-2">Billed to</p>
          <p v-if="isCardPayment">{{ receiptDetails.billerName }}</p>
          <p v-if="isCardPayment">{{ receiptDetails.billerAddress }}</p>
          <p>{{ receiptDetails.debtorName }}</p>
          <p>{{ receiptDetails.debtorAddress }}</p>
        </div>
        <template v-if="isCardPayment">
          <div class="flex mb-8">
            <img
              class="mr-3"
              :src="getCardLogo(receiptDetails.cardScheme)"
              :alt="receiptDetails.cardScheme.toString().toLowerCase()"
            />
            <div class="flex flex-col text-xs text-shades-darker">
              <span>
                {{ receiptDetails.cardScheme }}
              </span>
              <span>
                {{ receiptDetails.cardDetails }}
              </span>
            </div>
          </div>
        </template>

        <template v-else>
          <div class="flex flex-col mb-8 text-xs">
            <p>{{ receiptDetails.debtorBankName }}</p>
            <p>Account Number: {{ receiptDetails.debtorBankAccountNumber }}</p>
            <p>BSB: {{ receiptDetails.debtorBankBranchCode }}</p>
          </div>
        </template>
        <p v-show="isCardPayment" class="text-xs text-shades">
          Please note that this payment will be shown as
          <span class="font-bold"> RFS* XXXXX {{ brand.name }}</span> on your bank or credit card statement.
        </p>
      </div>
    </div>

    <template v-if="isCardPayment">
      <form @submit.prevent="sendReceipt" class="mt-8 flex flex-col">
        <p class="font-bold text-sm mb-2">Send a copy to email</p>
        <div class="flex flex-col lg:flex-row lg:justify-between">
          <BaseInput
            id="sendReceiptViaEmailInput"
            class="lg:mr-2 w-full"
            required
            type="email"
            label="Email address"
            title="Email is required"
            @focus="clearError"
            v-model="emailForm"
          />
          <BaseButton :loading="isLoading" :disabled="isLoading" type="submit" rounded class="lg:mb-4"
            >Send copy</BaseButton
          >
        </div>
        <notification variant="success" class="text-sm mb-4" v-show="emailSent && !isError">
          <BaseIcon icon="fas fa-check-circle" size="fa-xs" />
          Email has been successfully sent.</notification
        >
        <ErrorNotification v-show="isError" type="is-warning" :errorMessages="apiError" />
      </form>
    </template>

    <template v-else>
      <BaseButton @click="routeToDashboard" rounded class="w-full mt-10">View Payment Plan</BaseButton>
    </template>
  </div>
</template>

<style scoped>
.payment-receipt-row {
  @apply flex justify-between my-1;
}
</style>
