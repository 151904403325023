<script>
import { defineComponent } from '@vue/composition-api';
import { useBrand } from '@/use/brand';
import { useRouter } from '@/use/router';
import logo from '@/components/logo.vue';

export default defineComponent({
  name: 'ArtifactAlreadeyPaid',
  components: { logo },
  props: {
    artifactDetails: {
      type: Object,
      required: false,
    },
  },
  setup() {
    const brand = useBrand();
    const router = useRouter();
    return {
      brand,
      router,
    };
  },
});
</script>
<template>
  <div class="flex flex-col items-center justify-center px-2">
    <div class="w-full max-w-screen-lg pt-4">
      <logo size="small" class="" />
    </div>
    <div class="mt-20 max-w-screen-sm">
      <h1 class="heading-2 md:heading-1 text-center mb-8">This item has already been paid</h1>
      <p class="text-shades-dark">
        Sorry, it appears this item
        <span class="font-bold text-shades-darker"> (Payment reference: {{ artifactDetails.externalId }}) </span>has
        already been paid.
      </p>
      <p class="text-shades-dark mt-4">
        If you think this is a mistake or you have questions about your payment, please contact the
        {{ brand.name }} Support Team at
        <a class="cursor-pointer text-primary-dark" :href="`mailto:${brand.contactEmail}`"> {{ brand.contactEmail }}</a>
        or call us at <span class="cursor-pointer text-primary-dark"> {{ brand.contactPhone }}</span> with your payment
        reference.
      </p>
      <div class="my-4 text-shades-dark">
        <p>
          For questions about your legal bill, please contact
          <span class="font-bold text-shades-darker"> {{ artifactDetails.partyName }}</span> at:
        </p>
        <div class="text-shades-darker text-center mt-8 mb-2">
          <h3 class="font-bold text-lg">{{ artifactDetails.partyName }}</h3>
          <p>{{ artifactDetails.contactNumber }}</p>
          <p>{{ artifactDetails.contactEmail }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
