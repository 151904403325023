<script>
import { defineComponent, ref } from '@vue/composition-api';
import { BASE, PRIMARY, sizes, variants } from '@/lib/variants';

export default defineComponent({
  name: 'BaseCheckbox',
  props: {
    variant: {
      type: String,
      required: false,
      default: PRIMARY,
      validator: (value) => Object.values(variants()).includes(value),
    },
    size: {
      type: String,
      required: false,
      default: BASE,
      validator: (value) => Object.values(sizes()).includes(value),
    },
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const baseCheckbox = ref(null);
    function emitChange(event) {
      event.preventDefault();
      this.$emit('input', event.target.checked);
    }
    return { emitChange, baseCheckbox };
  },
});
</script>

<template>
  <label class="flex items-center cursor-pointer">
    <div :class="['flex items-center justify-center border hover:ring-1 bg-transparent flex-shrink-0', variant, size]">
      <input
        ref="baseCheckbox"
        :checked="value"
        type="checkbox"
        :required="required"
        @change.prevent="emitChange"
        :class="['base-checkbox-check appearance-none cursor-pointer', variant, size]"
      />
    </div>
    <span class="ml-2"> <slot /> </span>
  </label>
</template>

<style scoped>
.primary {
  @apply border-primary hover:ring-primary;
}
.base-checkbox-check:checked.primary {
  @apply bg-primary;
}
.dark {
  @apply border-dark hover:ring-dark;
}
.base-checkbox-check:checked.dark {
  @apply bg-dark;
}
.accent {
  @apply border-accent hover:ring-accent;
}
.base-checkbox-check:checked.accent {
  @apply bg-accent;
}
.shades {
  @apply border-shades hover:ring-shades;
}
.base-checkbox-check:checked.shades {
  @apply bg-shades;
}
.success {
  @apply border-success hover:ring-success;
}
.base-checkbox-check:checked.success {
  @apply bg-success;
}
.warning {
  @apply border-warning-dark hover:ring-warning-dark;
}
.base-checkbox-check:checked.warning {
  @apply bg-warning-dark;
}
.danger {
  @apply border-danger hover:ring-danger;
}
.base-checkbox-check:checked.danger {
  @apply bg-danger;
}
.base {
  @apply h-6 w-6 rounded;
}
.base-checkbox-check:checked.base {
  @apply w-4 h-4 rounded;
}
.small {
  @apply h-4 w-4;
}
.base-checkbox-check:checked.small {
  @apply w-3 h-3;
}
.smaller {
  @apply h-2 w-2;
}
.base-checkbox-check:checked.smaller {
  @apply w-1 h-1;
}
.large {
  @apply h-8 w-8;
}
.base-checkbox-check:checked.large {
  @apply w-6 h-6 rounded;
}
.larger {
  @apply h-10 w-10;
}
.base-checkbox-check:checked.larger {
  @apply w-8 h-8 rounded;
}
</style>
