<script>
import { computed, defineComponent, ref } from '@vue/composition-api';
import PaymentOptionsCard from '@/components/payment-options-card.vue';
import PaymentRequestHeader from '@/components/payment-request-header.vue';
import BaseIcon from '@/components/base/base-icon.vue';
import BaseModal from '@/components/base/base-modal.vue';
import Notification from '@/components/notification.vue';
import Logo from '@/components/logo.vue';
import { formatCurrency } from '@/lib/formats';
import { useRouter } from '@/use/router';
import { ROUTE_ARTIFACT_RECEIPT } from '@/lib/router';
import UnnaprovedForPlanInfoCard from '@/components/unapproved-for-plan-info-card.vue';

export default defineComponent({
  components: {
    BaseIcon,
    BaseModal,
    PaymentOptionsCard,
    PaymentRequestHeader,
    Notification,
    Logo,
    UnnaprovedForPlanInfoCard,
  },
  name: 'CardPayment',
  props: {
    artifactDetails: {
      type: Object,
      required: true,
    },
    paymentOptions: {
      type: Array,
      required: true,
    },
    isBnplApproved: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const showModal = ref(false);
    function toggleModal() {
      showModal.value = !showModal.value;
    }

    const router = useRouter();
    function routeToReceipt(receiptDetails) {
      router.push({
        name: ROUTE_ARTIFACT_RECEIPT,
        params: {
          artifactId: props.artifactDetails.artifactId,
          receiptDetails,
        },
      });
    }
    const cardPaymentOption = computed(() => props.paymentOptions.find((item) => item.productType === 'Card'));
    return {
      showModal,
      toggleModal,
      cardPaymentOption,
      formatCurrency,
      routeToReceipt,
    };
  },
});
</script>

<template>
  <div class="flex flex-col-reverse lg:flex-row justify-center px-2 w-full max-w-screen-lg mx-auto">
    <div>
      <h1 class="lg:heading-1 heading-2 mt-6 lg:mt-24 my-4">Pay via card</h1>
      <div v-if="!isBnplApproved" class="flex my-6">
        <Notification variant="shades" class="flex flex-col-reverse md:flex-row text-xs">
          <p class="text-sm py-2 md:py-4">
            Unfortunately, we were unable to approve you for a Payment Plan this time but you can still complete payment
            via credit or debit card.
          </p>
          <Logo class="md:ml-2" size="smaller" />
        </Notification>
        <span @click="toggleModal" class="mr-2 md:ml-4 ml-2 flex items-center text-warning cursor-pointer">
          <BaseIcon icon="fa-question-circle" size="fa-lg" />
        </span>
      </div>
      <PaymentOptionsCard
        class="max-w-screen-lg"
        @receipt-details="routeToReceipt"
        :cardPaymentOption="cardPaymentOption"
        :artifactId="artifactDetails.artifactId"
      />
    </div>
    <div class="lg:py-24 lg:px-5">
      <PaymentRequestHeader :artifactDetails="artifactDetails" />
    </div>
    <BaseModal :show.sync="showModal">
      <div class="flex justify-end p-2">
        <span class="cursor-pointer" @click="toggleModal">
          <BaseIcon icon="fa-times" size="fa-lg" />
        </span>
      </div>
      <UnnaprovedForPlanInfoCard />
    </BaseModal>
  </div>
</template>

<style scoped>
</style>
