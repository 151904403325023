<template>
  <BaseInput
    :id="id"
    type="text"
    :required="required"
    :title="title"
    :value="value"
    :label="label"
    @input="emitInput"
  />
</template>

<script>
import BaseInput from '@/components/base/base-input.vue';
import { auStateFromCode } from '@/lib/registration';

export default {
  name: 'AddressFinder',
  components: {
    BaseInput,
  },
  mounted() {
    this.createWidget();
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    /**
     * The value is the address string entered / selected
     */
    value: {
      type: [String, Number],
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Field is required',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * The label is the header inside the input that indicates what the input is for
     * Example: "Enter your address"
     */
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      address: {
        rawAddress: '',
        address1: '',
        address2: '',
        city: '',
        provinceOrState: '',
        provinceOrStateCode: '',
        country: '',
        countryCode: '',
        postalCode: '',
      },
    };
  },
  methods: {
    emitInput(value) {
      this.$emit('input', value);
    },
    emitSelected() {
      this.$emit('address-selected', this.address, this.id);
    },
    createWidget() {
      let widget;
      try {
        // eslint-disable-next-line no-undef
        widget = new AddressFinder.Widget(
          document.getElementById(this.id),
          `${process.env.VUE_APP_ADDRESS_FINDER_KEY}`,
          'AU',
          {
            address_params: {
              gnaf: 1,
            },
          },
        );
        widget.on('result:select', (fullAddress, metaData) => {
          this.address.rawAddress = fullAddress;
          this.address.address1 = metaData.address_line_1;
          this.address.address2 = metaData.address_line_2;
          this.address.city = metaData.locality_name;
          this.address.provinceOrState = auStateFromCode(metaData.state_territory);
          this.address.provinceOrStateCode = metaData.state_territory;
          this.address.postalCode = metaData.postcode;
          // Address finder is AU only
          this.address.country = 'Australia';
          this.address.countryCode = 'AU';
          this.emitSelected();
        });
      } catch (error) {
        widget = {};
      }
    },
  },
};
</script>

<style></style>
