<script>
import { defineComponent, ref, computed } from '@vue/composition-api';
import BaseIcon from '@/components/base/base-icon.vue';
import Logo from '@/components/logo.vue';
import BaseModal from '@/components/base/base-modal.vue';
import PlanSchedule from '@/components/plan-schedule.vue';
import InstalmentInfoCard from '@/components/instalment-info-card.vue';
import { formatCurrency } from '@/lib/formats';

export default defineComponent({
  components: { Logo, BaseIcon, PlanSchedule, BaseModal, InstalmentInfoCard },
  name: 'PaymentOptionBnpl',
  props: {
    paymentOption: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const showModal = ref(false);
    function toggleModal() {
      showModal.value = !showModal.value;
    }

    const recurringRepayment = computed(() => props.paymentOption.schedule[1]);

    return {
      showModal,
      toggleModal,
      recurringRepayment,
      formatCurrency,
    };
  },
});
</script>

<template>
  <div class="flex flex-col">
    <div class="flex flex-col">
      <div class="flex items-center mb-4">
        <h3 class="heading-3 mr-2">Payment plans with</h3>
        <Logo size="small" class="mr-2" />
        <span @click="toggleModal" class="text-warning cursor-pointer">
          <BaseIcon icon="fa-info-circle" size="fa-md" />
        </span>
      </div>
      <p class="text-sm mb-2">
        Pay in <span class="font-bold">{{ paymentOption.repaymentCount }} fortnightly</span> of
        <span class="font-bold">
          {{ formatCurrency(recurringRepayment.amount) }} over {{ paymentOption.productFriendly }}</span
        >
        with 0% interest.
      </p>
      <p class="text-sm text-shades-darker mb-4">
        To pay with a payment plan, you will need to create an account. Signing up takes only 5 minutes and we will do a
        quick eligibility check right after.
      </p>
    </div>
    <PlanSchedule :paymentOption="paymentOption" />
    <div class="w-full mt-6">
      <slot />
    </div>
    <BaseModal :show.sync="showModal">
      <div class="flex justify-end p-2">
        <span class="cursor-pointer" @click="toggleModal">
          <BaseIcon icon="fa-times" size="fa-lg" />
        </span>
      </div>
      <InstalmentInfoCard />
    </BaseModal>
  </div>
</template>

<style scoped>
</style>
