<script>
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'PaymentOptionsTabs',
  props: {
    /**
     * render the tabs horizontally (built for desktop / large devices)
     */
    horizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const openTab = ref(props.horizontal ? 1 : 0);

    function toggleTabs(tabNumber) {
      openTab.value = tabNumber;
    }

    function isHeaderSelected(tabId = 0) {
      if (props.horizontal) {
        return openTab.value === tabId ? 'payment-option-selected-horizontal' : 'bg-shades-lighter';
      }
      return openTab.value === tabId ? 'payment-option-selected' : 'bg-white';
    }

    function isTabActive(tabId = 0) {
      return openTab.value === tabId ? 'static' : 'hidden';
    }

    return {
      openTab,
      toggleTabs,
      isHeaderSelected,
      isTabActive,
    };
  },
});
</script>

<template>
  <div v-if="horizontal" class="w-full">
    <ul class="flex">
      <li @click="toggleTabs(1)" :class="['payment-option-tab-header-horizontal', isHeaderSelected(1)]">
        <span class="font-semibold"> Pay via Credit/Debit Card </span>
        <span class="text-xs text-shades-dark">Pay in full using all major debit and credit cards today</span>
      </li>
      <li @click="toggleTabs(2)" :class="['payment-option-tab-header-horizontal', isHeaderSelected(2)]">
        <span class="font-semibold"> Pay later via Payment Plan </span>
        <span class="text-xs text-shades-dark"> 0% interest payment plans, direct debited fortnightly </span>
      </li>
    </ul>
    <div class="flex flex-col w-full">
      <div class="px-4 py-5">
        <div :class="isTabActive(1)">
          <slot name="payment-option-card" />
        </div>
        <div :class="isTabActive(2)">
          <slot name="payment-option-instalments" />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="w-full">
    <ul class="flex flex-col pt-3 pb-1">
      <li @click="toggleTabs(1)" :class="['payment-option-tab-header', isHeaderSelected(1)]">
        <div class="flex justify-between">
          <div class="flex flex-col">
            <span class="font-semibold"> Pay via Card </span>
            <span class="text-xs text-shades-dark">Pay in full today</span>
          </div>
          <div class="flex items-center justify-center">
            <img src="@/assets/card-icon-amex.svg" alt="amex card" />
            <img src="@/assets/card-icon-mastercard.svg" alt="mastercard card" />
            <img src="@/assets/card-icon-visa.svg" alt="visa card" />
          </div>
        </div>
      </li>
      <div class="flex flex-col w-full">
        <div class="px-4 py-3">
          <div :class="isTabActive(1)">
            <slot name="payment-option-card" />
          </div>
        </div>
      </div>
      <li @click="toggleTabs(2)" :class="['payment-option-tab-header', isHeaderSelected(2)]">
        <span class="font-semibold"> Pay later via Payment Plan </span>
        <span class="text-xs text-shades-dark"> 0% interest payment plans, direct debited fortnightly </span>
      </li>
      <div class="flex flex-col w-full">
        <div class="px-4 py-3">
          <div :class="isTabActive(2)">
            <slot name="payment-option-instalments" />
          </div>
        </div>
      </div>
    </ul>
  </div>
</template>

<style scoped>
.payment-option-selected-horizontal {
  @apply bg-white border-b-4 border-primary;
}
.payment-option-tab-header-horizontal {
  @apply px-3 py-6 flex flex-col w-1/2 cursor-pointer rounded-t-lg;
}
.payment-option-selected {
  @apply bg-white border-primary;
}
.payment-option-tab-header {
  @apply px-3 py-4 flex flex-col w-full cursor-pointer rounded-lg border;
}
</style>
