<script>
import { defineComponent, computed } from '@vue/composition-api';
import { formatCurrency, formatDate } from '@/lib/formats';
import BaseCheckbox from '@/components/base/base-checkbox.vue';
import BaseButton from '@/components/base/base-button.vue';
import BaseModal from '@/components/base/base-modal.vue';
import BaseIcon from '@/components/base/base-icon.vue';
import Logo from '@/components/logo.vue';
import InstalmentInfoCard from '@/components/instalment-info-card.vue';

export default defineComponent({
  name: 'PlanSchedule',
  components: {
    BaseCheckbox,
    BaseButton,
    BaseModal,
    BaseIcon,
    Logo,
    InstalmentInfoCard,
  },
  props: {
    paymentOption: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const firstRepayment = computed(() => props.paymentOption.schedule[0]);
    const recurringRepayment = computed(() => props.paymentOption.schedule[1]);
    const finalRepayment = computed(() => props.paymentOption?.schedule[props.paymentOption.schedule.length - 1]);

    return {
      formatCurrency,
      formatDate,
      firstRepayment,
      recurringRepayment,
      finalRepayment,
    };
  },
});
</script>
<template>
  <div>
    <div class="border border-shades-lighter rounded-lg border-t-0">
      <div class="text-white bg-primary rounded-t-lg flex flex-col justify-between p-4">
        <div class="flex justify-between w-full">
          <p class="font-bold text-lg mb-1.5">Plan details</p>
          <p class="font-bold mb-1.5 text-right">
            {{ formatCurrency(recurringRepayment.amount) }}
            per fortnight over {{ paymentOption.productFriendly }}
          </p>
        </div>
        <p class="mb-1.5 font-smaller text-right text-xs">
          {{ formatDate(firstRepayment.dueDate) }} -
          {{ formatDate(finalRepayment.dueDate) }}
        </p>
      </div>
      <div class="items-center p-4 mt-2">
        <div class="flex justify-between text-center w-full">
          <p class="font-bold">Paid Today</p>
          <p class="font-bold">
            {{ formatCurrency(firstRepayment.amount) }}
          </p>
        </div>
        <div class="flex justify-between">
          <p class="text-xs text-shades-darker">
            {{ formatDate(firstRepayment.dueDate) }}
          </p>
          <p class="text-xs text-shades-darker">
            (incl. Plan Setup Fee of
            {{ formatCurrency(paymentOption.establishmentFee) }})
          </p>
        </div>
      </div>
      <div class="items-center p-4 mt-2">
        <div class="flex justify-between text-center w-full">
          <p class="font-bold">Next Payment</p>
          <p class="font-bold">
            {{ formatCurrency(recurringRepayment.amount) }}
          </p>
        </div>
        <p class="text-xs text-shades-darker">Direct Debited {{ formatDate(recurringRepayment.dueDate) }}</p>
      </div>
      <div class="items-center p-4 mt-2">
        <div class="flex justify-between text-center w-full border-l border-shades-lighter">
          <p class="text-xs text-shades-darker text-left ml-2">
            Including next payment, your plan will have
            <span class="font-semibold"> {{ paymentOption.repaymentCount - 1 }}</span>
            additional payments of
            <span class="font-semibold">{{ formatCurrency(recurringRepayment.amount) }}</span
            >.
            <br />
            These are automatically direct debited every two weeks until the final payment is taken.
          </p>
        </div>
      </div>
      <div class="items-center p-4 mt-2">
        <div class="flex justify-between text-center w-full">
          <p class="font-bold">Final Payment</p>
          <p class="value-subtitle font-bold">{{ formatCurrency(finalRepayment.amount) }}</p>
        </div>
        <p class="text-xs text-shades-darker">Direct Debited {{ formatDate(finalRepayment.dueDate) }}</p>
      </div>
      <div class="items-center p-4 mt-2 text-lg">
        <div class="flex justify-between w-full">
          <p class="font-bold">Total Paid Over Period</p>
          <p class="font-bold">
            {{ formatCurrency(paymentOption.totalAmount) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
