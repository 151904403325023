<script>
import { defineComponent } from '@vue/composition-api';
import CreditCardForm from '@/containers/credit-card-form.vue';
import Notification from '@/components/notification.vue';
import { useBrand } from '@/use/brand';
import { formatCurrency } from '@/lib/formats';

export default defineComponent({
  name: 'PaymentOptionsCard',
  props: {
    artifactId: {
      type: String,
      required: true,
    },
    cardPaymentOption: {
      type: Object,
      required: false,
    },
  },
  components: { CreditCardForm, Notification },
  setup(_, { emit }) {
    const brand = useBrand();
    function emitReceiptDetails(details) {
      emit('receipt-details', details);
    }
    return {
      brand,
      formatCurrency,
      emitReceiptDetails,
    };
  },
});
</script>

<template>
  <div class="w-full">
    <div v-if="cardPaymentOption">
      <p class="font-semibold">
        Pay now {{ formatCurrency(cardPaymentOption.totalAmount) }} (including surcharge of
        {{ formatCurrency(cardPaymentOption.establishmentFee) }})
      </p>
      <div class="my-6 flex items-center">
        <img class="pr-2" src="@/assets/card-icon-amex.svg" alt="amex card" />
        <img src="@/assets/card-icon-mastercard.svg" alt="mastercard card" />
        <img class="px-2" src="@/assets/card-icon-visa.svg" alt="visa card" />
        <img src="@/assets/card-icon-jcb.svg" alt="jcb card" />
      </div>
      <CreditCardForm
        @receipt-details="emitReceiptDetails"
        :artifactId="artifactId"
        :totalAmount="cardPaymentOption.totalAmount"
      />
    </div>
    <notification v-else variant="warning">
      <p class="text-sm pb-2">
        Sorry, our card payments are currently unavailable. Please try again later or contact our support at
        <span class="font-bold"> {{ brand.contactEmail }}</span> or call us at
        <span class="font-bold">{{ brand.contactPhone }}</span
        >.
      </p>
      <p class="text-sm">For questions about payment options and paying your invoice, please contact the law firm.</p>
    </notification>
  </div>
</template>

<style scoped>
</style>
