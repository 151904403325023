import { render, staticRenderFns } from "./artifact-already-paid.vue?vue&type=template&id=86345e90&scoped=true&"
import script from "./artifact-already-paid.vue?vue&type=script&lang=js&"
export * from "./artifact-already-paid.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86345e90",
  null
  
)

export default component.exports