import { render, staticRenderFns } from "./payment-request-header.vue?vue&type=template&id=3bf57a55&scoped=true&"
import script from "./payment-request-header.vue?vue&type=script&lang=js&"
export * from "./payment-request-header.vue?vue&type=script&lang=js&"
import style0 from "./payment-request-header.vue?vue&type=style&index=0&id=3bf57a55&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bf57a55",
  null
  
)

export default component.exports