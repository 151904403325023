<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'IconCheckCircle',
  props: {
    fill: {
      type: String,
      required: false,
      default: 'black',
    },
    width: {
      type: String,
      required: false,
      default: '111',
    },
    height: {
      type: String,
      required: false,
      default: '108',
    },
  },
  setup() {},
});
</script>

<template>
  <svg :width="width" :height="height" viewBox="0 0 111 108" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      :d="`M54 9C29.1472 9 9 29.1472 9 54C9 78.8528 29.1472 99 54
            99C78.8528 99 99 78.8528 99 54C99 48.2983 97.9418 42.8544
            96.0151 37.8466C95.1227 35.5271 96.2796 32.9233 98.5991
            32.0309C100.919 31.1385 103.522 32.2954 104.415
            34.6149C106.732 40.6377 108 47.1761 108 54C108
            83.8234 83.8234 108 54 108C24.1766 108 0 83.8234 0 54C0
            24.1766 24.1766 0 54 0C65.2945 0 75.7915 3.47245 84.4646
            9.40833C86.5156 10.812 87.0403 13.6125 85.6366 15.6634C84.233
            17.7144 81.4325 18.2391 79.3815 16.8354C72.1578 11.8915 63.4234 9 54 9Z`"
      :fill="fill"
    />
    <path
      d="M108.939 5.74355C110.697 7.50091 110.697 10.3502 108.939 12.1075L53.8786
            70.6837C52.1245 72.4378 49.2818 72.4416 47.5231 70.6922L28.3265 51.5971C26.5645
            49.8444 26.5569 46.995 28.3096 45.233C30.0623 43.471 32.9115 43.4634 34.6735
            45.2161L50.6882 61.1462L102.575 5.74356C104.333 3.9862 107.182 3.9862 108.939 5.74355Z"
      :fill="fill"
    />
    <path
      d="M108.939 5.74355C110.697 7.50091 110.697 10.3502 108.939 12.1075L53.8786 70.6837C52.1245
            72.4378 49.2818 72.4416 47.5231 70.6922L28.3265 51.5971C26.5645 49.8444 26.5569 46.995 28.3096
            45.233C30.0623 43.471 32.9115 43.4634 34.6735 45.2161L50.6882 61.1462L102.575 5.74356C104.333
            3.9862 107.182 3.9862 108.939 5.74355Z"
      stroke="white"
    />
  </svg>
</template>

<style scoped>
</style>
