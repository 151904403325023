<script>
import { defineComponent } from '@vue/composition-api';
import { useBrand } from '@/use/brand';

export default defineComponent({
  name: 'InstalmentInfoCard',
  setup() {
    const brand = useBrand();
    return {
      brand,
    };
  },
});
</script>

<template>
  <div class="flex flex-col m-4">
    <p class="heading-2 mb-2">How do Payment Plans work?</p>
    <p class="text-shades-darker mb-4 text-sm">
      Payment Plans are offered by Rapid Payment Plans (ABN 70 634 811 671). You must create an account and be approved
      based on our banking and eligibilty checks - this won't affect your credit history.
    </p>
    <p class="text-shades-darker mb-4 text-sm">
      All payment plans are subject to approval up to a total maximum limit of $10,000. Should you reach this limit, you
      must pay off some of your existing payment plans before requesting any further advance. Your account is operated
      as a continuing credit contract.
    </p>
    <p class="text-shades-darker mb-4 text-sm">
      Your account and payment plans are subject to our proprietary assessment of your affordability and ability to
      repay.
    </p>
    <p class="text-shades-darker text-sm">To apply, you must:</p>
    <div class="ml-6">
      <ul class="list-disc text-shades-darker">
        <li>be over 18 years of age</li>
        <li>be an Australian Citizen or Permanent Resident</li>
        <li>have an Australian bank account which receives income of $1,500 per month or more</li>
      </ul>
    </div>
    <p class="text-shades-darker mt-4 text-sm">
      Please note that a Plan Setup Fee can apply. Late fees may also apply if you do not pay per schedule.
    </p>
    <p class="text-shades-darker text-sm">
      For more information, please read the
      <a class="text-link" target="_blank" :href="brand.customerTerms">terms and conditions</a> and
      <a class="text-link" target="_blank" :href="brand.faqs">FAQs</a>. You may also call us at.
      <span>{{ brand.contactPhone }}</span>
    </p>
  </div>
</template>

<style scoped></style>
