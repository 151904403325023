<script>
/* eslint-disable no-undef */
import { v4 as uuid } from 'uuid';
import { computed, defineComponent, onMounted, reactive, ref } from '@vue/composition-api';
import BaseInput from '@/components/base/base-input.vue';
import BaseButton from '@/components/base/base-button.vue';
import AddressFinder from '@/components/address-finder.vue';

import { postCardPayment, postRequestToken } from '@/api/card-payment';
import Notification from '@/components/notification.vue';
import { useBrand } from '@/use/brand';
import { useApiErrors } from '@/use/errors';
import ErrorNotification from '@/components/error-notification.vue';

export default defineComponent({
  name: 'CreditCardForm',
  props: {
    totalAmount: {
      type: String,
      required: true,
    },
    artifactId: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseInput,
    BaseButton,
    AddressFinder,
    Notification,
    ErrorNotification,
  },
  setup(props, { emit }) {
    const elCardNumber = ref(null);
    const elCardSecCode = ref(null);
    const addressUniqueId = uuid();
    const brand = useBrand();
    const cardPaymentToken = ref('');
    const isLoading = ref(false);
    const isMicroformError = ref(false);
    const form = reactive({
      firstName: '',
      lastName: '',
      address: {
        rawAddress: '',
        address1: '',
        address2: '',
        city: '',
        provinceOrState: '',
        provinceOrStateCode: '',
        country: '',
        countryCode: '',
        postalCode: '',
      },
      cardDetails: {
        expiry: '',
      },
      amount: props.totalAmount,
    });

    const { isError, apiError, setError, clearError } = useApiErrors(
      null,
      'An error occurred processing this payment. Please check your details and try again or contact us via support@rapidpaylegal.com',
    );

    let microform;
    const shortCircuitTimeout = 3000;
    let shortCircuitTimer;

    function clearShortCircuit() {
      isLoading.value = false;
      clearTimeout(shortCircuitTimer);
    }
    function shortCircuitCardProvider() {
      isLoading.value = false;
      isMicroformError.value = true;
    }
    function setCardPaymentProvider() {
      try {
        const flex = new Flex(cardPaymentToken.value);
        microform = flex.microform({
          input: {
            'font-size': '16px',
            'font-family': 'Mulish, helvetica, tahoma, calibri, sans-serif',
          },
          '::placeholder': {},
          ':disabled': { cursor: 'not-allowed' },
        });
        const cardNumberField = microform.createField('number', { placeholder: 'xxxx xxxx xxxx xxxx' });
        cardNumberField.on('load', () => {
          clearShortCircuit();
        });
        cardNumberField.on('change', () => {
          clearError();
        });
        const cardSecurityCodeField = microform.createField('securityCode', { placeholder: 'xxx' });
        cardSecurityCodeField.on('change', () => {
          clearError();
        });
        cardNumberField.load(elCardNumber.value);
        cardSecurityCodeField.load(elCardSecCode.value);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        isMicroformError.value = true;
      } finally {
        isLoading.value = false;
      }
    }

    onMounted(async () => {
      try {
        isLoading.value = true;
        const { data } = await postRequestToken();
        cardPaymentToken.value = data?.keyId;

        shortCircuitTimer = setTimeout(shortCircuitCardProvider, shortCircuitTimeout);
        setCardPaymentProvider();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        isMicroformError.value = true;
      } finally {
        isLoading.value = false;
      }
    });

    function updateAddress(address) {
      form.address.address1 = address.address1;
      form.address.address2 = address.address2;
      form.address.city = address.city;
      form.address.provinceOrState = address.provinceOrState;
      form.address.provinceOrStateCode = address.provinceOrStateCode;
      form.address.country = address.country;
      form.address.countryCode = address.countryCode;
      form.address.postalCode = address.postalCode;
    }

    function createPaymentToken() {
      return new Promise((resolve, reject) => {
        const [month, year] = form.cardDetails.expiry.split('/');
        // TODO - investigate further this cleave-ism
        let parsedYear;
        if (year.length >= 3) {
          parsedYear = `20${year.substring(0, year.length - 1)}`;
        } else {
          parsedYear = `20${year}`;
        }
        const payload = {
          expirationMonth: month,
          expirationYear: parsedYear,
        };
        microform.createToken(payload, (err, token) => {
          if (err) {
            reject(err);
          }
          resolve(JSON.stringify(token));
        });
      });
    }

    async function submitCardPayment() {
      try {
        clearError();
        isLoading.value = true;
        const token = await createPaymentToken();
        const { data } = await postCardPayment({
          totalAmount: props.totalAmount,
          // TODO - remove this once payment request page is ready
          artifactId: props.artifactId,
          paymentToken: token,
          payer: {
            firstName: form.firstName,
            lastName: form.lastName,
            address1: form.address.address1,
            address2: form.address.address2,
            city: form.address.city,
            provinceOrState: form.address.provinceOrState,
            provinceOrStateCode: form.address.provinceOrStateCode,
            country: form.address.country,
            countryCode: form.address.countryCode,
            postalCode: form.address.postalCode,
          },
        });
        emit('receipt-details', data?.receiptDetails);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(error);
      } finally {
        isLoading.value = false;
      }
    }

    const buttonMessage = computed(() => (isMicroformError.value ? 'Card payment not available' : 'Pay in full now'));
    const buttonDisabled = computed(() => isMicroformError.value || isError.value || isLoading.value);

    return {
      elCardNumber,
      elCardSecCode,
      addressUniqueId,
      brand,
      cardPaymentToken,
      form,
      isLoading,
      isMicroformError,
      isError,
      apiError,
      clearError,
      updateAddress,
      submitCardPayment,
      buttonMessage,
      buttonDisabled,
    };
  },
});
</script>

<template>
  <form @submit.prevent="submitCardPayment" class="flex flex-col w-full">
    <notification v-if="isMicroformError" variant="warning" class="mb-4">
      <p class="text-sm pb-2">
        Sorry, our card payments are currently unavailable. Please try again later or contact our support at
        <span class="font-bold"> {{ brand.contactEmail }}</span> or call us at
        <span class="font-bold">{{ brand.contactPhone }}</span
        >.
      </p>
      <p class="text-sm">For questions about payment options and paying your invoice, please contact the law firm.</p>
    </notification>
    <div class="flex outline-none hover:ring-1 hover:ring-primary hover:border-transparent rounded mb-4">
      <div class="relative credit-card-label border border-r-0 rounded rounded-r-none px-2 w-3/5">
        <span class="absolute text-xs text-shades">Card Number</span>
        <div class="mt-2 card-number-container" ref="elCardNumber"></div>
      </div>
      <label class="credit-card-label indent-max border border-l-0 border-r-0 w-1/5">
        MM/YY
        <input
          required
          type="text"
          v-model="form.cardDetails.expiry"
          class="credit-card-input indent-max outline-none text-sm text-black font-semibold placeholder-shades-darker"
          placeholder="01/99"
          v-cleave="{ date: true, datePattern: ['m', 'y'] }"
          @click="clearError"
        />
      </label>
      <div class="relative credit-card-label border border-l-0 rounded rounded-l-none px-2 w-1/5">
        <span class="absolute text-xs text-shades"> CSV</span>
        <div ref="elCardSecCode" class="mt-2 card-security-container" />
      </div>
    </div>
    <div class="flex flex-col justify-between md:flex-row">
      <BaseInput
        class="flex-grow md:mr-2"
        id="firstNameInput"
        required
        label="First Name"
        v-model="form.firstName"
        title="First name is required"
        @click="clearError"
      />
      <BaseInput
        class="flex-grow"
        id="lastNameInput"
        required
        label="Last Name"
        v-model="form.lastName"
        title="Last name is required"
        @click="clearError"
      />
    </div>
    <AddressFinder
      :id="addressUniqueId"
      required
      class="flex-grow mb-2"
      label="Address"
      v-model="form.address.rawAddress"
      @address-selected="updateAddress"
    />
    <ErrorNotification class="mt-4" v-show="isError" :errorMessages="apiError" />
    <BaseButton :loading="isLoading" :disabled="buttonDisabled" rounded class="my-6 w-full">
      {{ buttonMessage }}</BaseButton
    >
  </form>
</template>

<style scoped>
.card-number-container,
.card-security-container {
  height: 3rem;
}
.credit-card-label {
  @apply inline-flex
        relative
        flex-col
        text-shades
        text-xs
        border-shades
        h-12;
}
.credit-card-input {
  margin-top: 6px;
}
.indent-max {
  text-indent: 0.5rem;
}
</style>
