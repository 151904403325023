<script>
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'RequestPending',
  setup() {
    const isPending = ref(true);

    return {
      isPending,
    };
  },
});
</script>

<template>
  <div class="max-w-screen-sm mx-auto mt-20">
    <h1 class="heading-1 mb-2 text-center">Please wait, assessing your application</h1>
    <div class="my-6 flex flex-col justify-center items-center">
      <img class="my-10" src="@/assets/hourglass.svg" alt="hourglass" />
      <transition name="fade" mode="out-in">
        <p v-if="isPending" class="text-shades-dark text-center text-xs">
          This will take less than a minute. Please don't navigate away from this page.
        </p>
        <div v-else class="flex flex-row">
          <img class="mr-3" src="@/assets/tick-circle.svg" alt="approved tick" />
          <p class="text-sm md:text-base text-shades-dark">Approved!</p>
        </div>
      </transition>
    </div>
  </div>
</template>
<style scoped>
</style>
