<script>
import { computed, defineComponent, ref } from '@vue/composition-api';
import { formatCurrency } from '@/lib/formats';

export default defineComponent({
  name: 'PaymentRequestHeader',
  props: {
    artifactDetails: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expandHeader = ref(false);
    const expandText = computed(() => (expandHeader.value ? 'hide details' : 'view details'));
    function toggleExpandHeader() {
      expandHeader.value = !expandHeader.value;
    }

    return {
      formatCurrency,
      expandText,
      expandHeader,
      toggleExpandHeader,
    };
  },
});
</script>

<template>
  <div>
    <div class="lg:hidden flex flex-col">
      <div v-show="artifactDetails.partyLogo" class="flex mb-2 h-28 overflow-hidden">
        <img class="mx-auto h-28 max-w-full rounded" :src="artifactDetails.partyLogo" alt="merchant logo" />
      </div>
      <div
        :class="[
          'payment-request-header',
          `flex
        flex-col
        text-xs
        justify-between
        rounded-xl
        shadow-md
        bg-white
        p-6
        overflow-hidden
        lg:hidden`,
          { expand: expandHeader },
        ]"
      >
        <div class="flex flex-col w-full justify-center items-center">
          <p class="text-sm text-shades-dark">Amount Due</p>
          <span class="text-xl font-black my-1">{{ formatCurrency(artifactDetails.amount) }}</span>
        </div>
        <span class="text-center">
          to <span class="font-bold">{{ artifactDetails.partyName }}</span>
        </span>
        <div class="flex mt-2 text-shades-darker justify-center">
          <p>Reference {{ artifactDetails.externalId }}</p>
        </div>
        <a class="text-link mb-4 text-center" @click="toggleExpandHeader">{{ expandText }}</a>
        <div class="flex flex-col items-center">
          <p class="font-bold mb-2 text-lg">Firm details</p>
          <span> ABN: {{ artifactDetails.partyLegalIdentifier }}</span>
          <span> {{ artifactDetails.partyAddress }} </span>
          <span class="mt-4">{{ artifactDetails.contactNumber }}</span>
          <span>{{ artifactDetails.contactEmail }}</span>
        </div>
      </div>
    </div>
    <div class="hidden lg:flex flex-col">
      <div v-show="artifactDetails.partyLogo" class="flex justify-center items-center w-80 mb-2">
        <img class="mx-auto rounded max-h-80" :src="artifactDetails.partyLogo" alt="merchant logo" />
      </div>
      <div class="justify-between rounded-xl shadow-md p-6 md:w-80">
        <div class="flex flex-col text-xs w-full">
          <p class="text-xl font-semibold text-shades-dark">Amount Due</p>
          <span class="text-3xl font-black">{{ formatCurrency(artifactDetails.amount) }}</span>
          <span class="font-semibold mb-6 text-base">{{ artifactDetails.partyName }}</span>
          <div class="flex w-full justify-between font-bold text-shades-darker">
            <p>Reference</p>
            <span>{{ artifactDetails.externalId }}</span>
          </div>
          <p class="font-bold mt-6 mb-2 text-lg">Firm details</p>
          <span> ABN: {{ artifactDetails.partyLegalIdentifier }}</span>
          <span> {{ artifactDetails.partyAddress }} </span>
          <span class="mt-4">{{ artifactDetails.contactNumber }}</span>
          <span class="break-words">{{ artifactDetails.contactEmail }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.payment-request-header {
  height: 8.5rem;
}
.payment-request-header.expand {
  @apply h-full;
}
</style>
