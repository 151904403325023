<script>
import { defineComponent, computed, ref } from '@vue/composition-api';
import PaymentOptionsCard from '@/components/payment-options-card.vue';
import PaymentOptionBnpl from '@/components/payment-options-bnpl.vue';
import PaymentOptionsTabs from '@/components/payment-options-tabs.vue';
import PaymentRequestHeader from '@/components/payment-request-header.vue';
import { formatCurrency } from '@/lib/formats';
import BaseButton from '@/components/base/base-button.vue';
import { useRouter } from '@/use/router';
import { ROUTE_ARTIFACT_RECEIPT, ROUTE_PAY_CONFIRMATION, ROUTE_REGISTER, ROUTE_REGISTER_LINK_BANK } from '@/lib/router';
import { STATUS_CONFLICT } from '@/lib/api';
import { requestPaymentPlanAssessment } from '@/api/bnpl-payments';
import ErrorNotification from '@/components/error-notification.vue';
import { useApiErrors } from '@/use/errors';

export default defineComponent({
  components: {
    PaymentOptionsCard,
    PaymentOptionBnpl,
    PaymentOptionsTabs,
    PaymentRequestHeader,
    BaseButton,
    ErrorNotification,
  },
  name: 'PaymentRequest',
  props: {
    profile: {
      type: Object,
      required: false,
    },
    artifactDetails: {
      type: Object,
      required: true,
    },
    paymentOptions: {
      type: Array,
      required: true,
    },
    decisionsExist: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const router = useRouter();
    const { isError, apiError, setError, clearError } = useApiErrors(
      null,
      'An error occurred processing this payment. Please check your details and try again or contact us via  support@rapidpaylegal.com',
    );

    async function applyForBnpl() {
      try {
        clearError();
        isLoading.value = true;

        if (props.decisionsExist) {
          router.push({ name: ROUTE_PAY_CONFIRMATION, params: { artifactId: props?.artifactDetails?.artifactId } });
          return;
        }

        await requestPaymentPlanAssessment({
          artifactId: props?.artifactDetails?.artifactId,
          artifactType: props?.artifactDetails?.artifactType,
          partyId: props?.profile?.partyId,
        });

        router.push({ name: ROUTE_PAY_CONFIRMATION, params: { artifactId: props?.artifactDetails?.artifactId } });
      } catch (error) {
        if (error?.response?.status === STATUS_CONFLICT) {
          router.push({ name: ROUTE_REGISTER_LINK_BANK });
          return;
        }
        // eslint-disable-next-line no-console
        console.error(error);
        setError(error);
      } finally {
        isLoading.value = false;
      }
    }
    function signUpUser() {
      router.push({ name: ROUTE_REGISTER });
    }

    function routeToReceipt(receiptDetails) {
      router.push({
        name: ROUTE_ARTIFACT_RECEIPT,
        params: { receiptDetails, artifactId: props?.artifactDetails?.artifactId },
      });
    }

    const cardPaymentOption = computed(() => props.paymentOptions.find((item) => item.productType === 'Card'));
    const instalmentPaymentOption = computed(() => props.paymentOptions.find((item) => item.productType === '10week'));
    return {
      cardPaymentOption,
      instalmentPaymentOption,
      isLoading,
      formatCurrency,
      signUpUser,
      applyForBnpl,
      routeToReceipt,
      isError,
      apiError,
    };
  },
});
</script>

<template>
  <div class="flex flex-col-reverse lg:flex-row justify-center">
    <div class="lg:mt-16 mx-2 lg:mx-0">
      <h1 class="lg:heading-1 heading-2 mt-4 lg:mt-28 lg:my-10">How would you like to pay?</h1>
      <PaymentOptionsTabs class="max-w-screen-sm hidden lg:block" :horizontal="true">
        <template #payment-option-card>
          <div class="pb-2 pt-1">
            <slot name="payment-option-card">
              <PaymentOptionsCard
                @receipt-details="routeToReceipt"
                class="max-w-screen-md py-12"
                :artifactId="artifactDetails.artifactId"
                :cardPaymentOption="cardPaymentOption"
              />
            </slot>
          </div>
        </template>
        <template #payment-option-instalments>
          <div class="pb-2 pt-1">
            <slot name="#payment-option-instalments">
              <PaymentOptionBnpl class="max-w-screen-md" :paymentOption="instalmentPaymentOption">
                <ErrorNotification v-show="isError" class="my-4" :errorMessages="apiError" />
                <BaseButton
                  v-if="profile"
                  class="w-full"
                  rounded
                  @click="applyForBnpl"
                  :loading="isLoading"
                  :disabled="isError || isLoading"
                  >Get a Payment Plan assessment</BaseButton
                >
                <BaseButton v-else class="w-full" rounded @click="signUpUser" :loading="isLoading" :disabled="isLoading"
                  >Sign up for Pay later</BaseButton
                >
              </PaymentOptionBnpl>
            </slot>
          </div>
        </template>
      </PaymentOptionsTabs>
      <PaymentOptionsTabs class="max-w-screen-lg lg:hidden">
        <template #payment-option-card>
          <div class="pb-2 pt-1">
            <slot name="payment-option-card">
              <PaymentOptionsCard
                @receipt-details="routeToReceipt"
                class="px-2 max-w-screen-md"
                :artifactId="artifactDetails.artifactId"
                :cardPaymentOption="cardPaymentOption"
              />
            </slot>
          </div>
        </template>
        <template #payment-option-instalments>
          <div class="pb-2 pt-1">
            <slot name="#payment-option-instalments">
              <PaymentOptionBnpl class="px-2 max-w-screen-md" :paymentOption="instalmentPaymentOption">
                <ErrorNotification v-show="isError" class="my-4" :errorMessages="apiError" />
                <BaseButton
                  v-if="profile"
                  class="w-full"
                  rounded
                  @click="applyForBnpl"
                  :loading="isLoading"
                  :disabled="isError || isLoading"
                  >Get a Payment Plan assessment</BaseButton
                >
                <BaseButton v-else class="w-full" rounded @click="signUpUser" :loading="isLoading" :disabled="isLoading"
                  >Sign up for Pay later</BaseButton
                >
              </PaymentOptionBnpl>
            </slot>
          </div>
        </template>
      </PaymentOptionsTabs>
    </div>
    <div class="lg:py-44 lg:px-5 mx-2 lg:mx-0">
      <PaymentRequestHeader :artifactDetails="artifactDetails" />
    </div>
  </div>
</template>

<style scoped>
</style>
